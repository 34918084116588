import React from 'react';
import SideNav from './SideNav'
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";

export default class Sidebar extends React.Component {
  render() {
    const RouterSideNav = withRouter(SideNav);
    return (
        <RouterSideNav></RouterSideNav>
    );
  }
}