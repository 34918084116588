import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { fetchRequestRightType } from '../actions/rightTypeAction';
import WatcherCreated from '../pages/applications/Watcher/WatcherCreated';

const mapStateToProps = state => {
	return{
		righttypeData: state.righttype
	}
}

const mapDispatchToProps = dispatch => {
	return{
		fetchRequestRightType: (data) =>dispatch(fetchRequestRightType(data))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(WatcherCreated)