import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Token from '../../../constants/Token';

import styled from 'styled-components';
import './Watcher.css';

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 6em;
  margin-left: 6em;
  margin-right: 2.5em;
  background-color: #FF8383;
  border-radius: 20px 20px 0% 0%;
  `; 
  const GridWrapper2 = styled.div`
    margin-left: 6em;
    margin-right: 2.5em;
    margin-bottom: 6em;
    height: 1000px;
    display: flex;
    background-color: white;
  `; 
export const WatcherCreated = ({righttypeData, fetchRequestRightType}) => {
    //const righttype = useSelector(state => state.righttype);
    //const dispatch = useDispatch();
    const [auth, setAuth] = useState(true);
    const [name, setName] = useState(true);
    const [surname, setSurname] = useState(true);
    const [login, setLogin] = useState(true);
    
    const token = Token();
    useEffect(() => {
        fetchRequestRightType(token)
    }, []);
    console.log(righttypeData);

    return(
        <>
            <GridWrapper>
                <h1>Watcher</h1>
                <div  className="watcher">
                    <a href="/watcher"> User List </a>
                    &nbsp;&nbsp;&nbsp;
                    <a href="/created"> Add User </a>
                </div>
            </GridWrapper>
            <GridWrapper2>
                <div id="container">
                    <div className="backconnect">
                        <h2>create user</h2>
                        <input type="text"
                            onChange={(event) => setSurname(event.target.value)} 
                            placeholder="Surname"/>

                        <input type="text"
                            onChange={(event) => setName(event.target.value)} 
                            placeholder="Name"/>
                        <input type="text"
                            onChange={(event) => setLogin(event.target.value)} 
                            placeholder="your.mail@AppField.com"/>

                        {
                            Array.isArray(righttypeData.righttype) ? (
                            <>
                                <select>
                                {
                                    righttypeData.righttype.map((item) => (
                                        <option value={item.right}>{item.right}</option>
                                    ))
                                }
                                </select>
                            </>
                            ) : (
                            <p>sjj</p>
                            )
                        }
                    </div>
                </div>
            </GridWrapper2>
        </>
    );
}
export default WatcherCreated; 