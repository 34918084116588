import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { fetchUserWatcher } from '../actions/watcherAction';
import Watcher from '../pages/applications/Watcher/Watcher';

const mapStateToProps = state => {
	return{
		watcherData: state.watcher
	}
}

const mapDispatchToProps = dispatch => {
	return{
		fetchUserWatcher: (data) =>dispatch(fetchUserWatcher(data))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Watcher)