import { combineReducers } from 'redux'

import userReducer from '../reducers/userReducer'
import watcherReducer from '../reducers/watcherReducer'
import rightTypeReducer from '../reducers/rightTypeReducer'

const rootReducer = combineReducers({
    user:userReducer,
    watcher: watcherReducer,
    righttype:rightTypeReducer,
})

export default rootReducer