import React from 'react';
import styled from "styled-components";
import NavItem  from './NavItem';

const StyledSideNav = styled.div`
  position: fixed;     /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
  height: 100%;
  width: 60px;     /* Set the width of the sidebar */
  z-index: 1;      /* Stay on top of everything */
  top: 3.7em;      /* Stay at the top */
  background-color: #343A40; /* Black */
  overflow-x: hidden;     /* Disable horizontal scroll */
  padding-top: 10px;
`;

class SideNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          activePath: '/',
          items: [
            {
              path: '/balisticks',
              name: 'Balisticks',
              letter : 'B',
              css : '#83A7FF',
              key: 1 
            },
            {
                path: '/sundial',
                name: 'Sundial',
                letter : 'S',
                css : '#FFC183',
                key: 2
              },
            {
              path: '/mytree',
              name: 'MyTree',
              letter : 'M',
              css : '#83FF87',
              key: 3
            },
            {
                path: '/river-flow',
                name: 'RiverFlow',
                letter : 'R',
                css : '#83FFFD',
                key: 4
              },
              {
                path: '/seed-stock',
                name: 'SeedStock',
                letter : 'SS',
                css : '#83FFCE',
                key: 5
              },
              {
                path: '/leaf-api',
                name: 'LeafAPI',
                letter : 'L',
                css : '#D6FF83',
                key: 6
              },
              {
                path: '/golden-apple',
                name: 'GoldenApple',
                letter : 'GA',
                css : '#FFFB83',
                key: 7
              },
              {
                path: '/git',
                name: 'Git',
                letter : 'G',
                css : '#8B83FF',
                key: 8
              },
              {
                path: '/harmony',
                name: 'Harmony',
                letter : 'Ha',
                css : '#B683FF',
                key: 9
              },
              {
                path: '/hotline',
                name: 'Hotline',
                letter : 'Ho',
                css : '#F683FF',
                key: 10
              },
            {
              path: '/watcher',
              name: 'Watcher',
              letter : 'W',
              css : '#FF8383',
              key: 11
            },
          ]
        }  
      }
      onItemClick = (path) => {
        this.setState({ activePath: path }); /* Sets activePath which causes rerender which causes CSS to change */
      }
      render() {
        const { items, activePath } = this.state;
        return (
          <StyledSideNav>
            {
              /* items = just array AND map() loops thru that array AND item is param of that loop */
              items.map((item) => {
                /* Return however many NavItems in array to be rendered */
                return (
                    <NavItem path={item.path} name={item.name} letter={item.letter} css={item.css} onItemClick={this.onItemClick} 
                        active={item.path === activePath} key={item.key}/>
                )
              })
            }
          </StyledSideNav>
        );
      }
}

export default SideNav;