import React from 'react';
import styled from 'styled-components';
import './Home.css';

export const Home = () => {

    return(
        <div className='home_back'>
            <div className="home_top">
                <div>
                    <h2>App Field</h2>
                </div>
                <div>
                    <a className="home_span" href="/">logIn</a>
                </div>
            </div>
            <div className="home_img">
                <span><i>la suite appfield vous permettra d'étendre vos capacités de 
                    champs d'actions et ainsi récolter les fruits de votre travail</i></span>
            </div>
            <div className="home_offer">
                <div className="home_element">
                    <div className="home_element_top">
                        <h1>Personal</h1>
                    </div>
                    <div className="home_element_under">
                    </div>
                </div>
                <div className="home_void"/>
                <div className="home_element">
                    <div className="home_element_top">
                        <h1>PME</h1>
                    </div>
                    <div className="home_element_under">
                        <ul>
                            <li>10 account </li>
                        </ul>
                    </div>
                </div>
                <div className="home_void"/>
                <div className="home_element">
                    <div className="home_element_top">
                        <h1>Compagny</h1>
                    </div>
                    <div className="home_element_under">
                        <ul>
                            <li>price per chair </li>
                            <li>illimited stockage </li>
                            <li>illimited project </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home; 