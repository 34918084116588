import axios from 'axios';
import * as APIConfig from '../constants/APIConfig'
import * as types from '../constants/ActionTypes'

export const fetchRightType = () => ({
	type: types.RIGHTTYPE,
})

export const fetchRightTypeSuccess = righttype => ({
	type: types.RIGHTTYPE_SUCCESS,
	payload: righttype
})

export const fetchRightTypeError = error => ({
	type: types.RIGHTTYPE_ERROR,
	payload: error
})

export const fetchRequestRightType = (data) => {
	return async (dispatch) => {
		dispatch(fetchRightType())
        axios.post(
            `${APIConfig.API_URI}/righttype`,
            data,
            {
            headers: APIConfig.HEADERS,
            method: 'post',
           })
        .then(response =>{
            const righttype = response.data;
            console.log(righttype)
            dispatch(fetchRightTypeSuccess(righttype));
        })
        .catch (error => {
            const errormsg = error.message;
            dispatch(fetchRightTypeError(errormsg));
        })
	}
}