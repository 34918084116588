export const HEADERS = {
    'Access-Control-Allow-Origin': '*',
    "Access-Control-Expose-Headers":"Access-Control-*",
    "Access-Control-Allow-Headers": "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept",
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
    'Allow': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
	'Content-Type': 'application/json',
	Accept: 'application/json'
}
// LOCAL
/*
export const API_URI = '';
export const PROXY = {
    host: 'http://localhost:5002/api/v1',
    port: 5002
  };
*/

// BUILD
export const API_URI = 'http://92.222.217.217:5002/api/v1';
//export const API_URI = '';
export const PROXY = {
    host: 'http://92.222.217.217',
    port: 5002
  };