import axios from 'axios';
import * as APIConfig from '../constants/APIConfig'
import * as types from '../constants/ActionTypes'

export const fetchWatcher = () => ({
	type: types.WATCHER_USER,
})

export const fetchWatcherSuccess = watcher => ({
	type: types.WATCHER_USER_SUCCESS,
	payload: watcher
})

export const fetchWatcherError = error => ({
	type: types.WATCHER_USER_ERROR,
	payload: error
})

export const fetchUserWatcher = (data) => {
	return async (dispatch) => {
		dispatch(fetchWatcher())
        axios.post(
            `${APIConfig.API_URI}/watcher`,
            data,
            {
            headers: APIConfig.HEADERS,
            method: 'post',
           })
        .then(response =>{
            const watcher = response.data;
            dispatch(fetchWatcherSuccess(watcher));
        })
        .catch (error => {
            const errormsg = error.message;
            dispatch(fetchWatcherError(errormsg));
        })
	}
}