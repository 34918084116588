import * as types from '../constants/ActionTypes'

const initialState = {
    loading: false,
    user: {},
    error: ''
}

const userReducer = (state=initialState, action) => {
	switch (action.type) {
		case types.LOGIN_USER:
			return {
				...state,
				loading: true,
                user: {},
                error: ''
			}
		case types.LOGIN_USER_SUCCESS:
			return {
				loading: false,
				user: action.payload,
                error:'' 
			}
		case types.LOGIN_USER_ERROR:
			return {
				loading: false,
                user: {},
				error: action.payload,
			}
        
        default:
            return state;
            
	}
}

export default userReducer;