import * as types from '../constants/ActionTypes'

const initialState = {
	loading: false,
    watcher: {},
    error: ''
}

const watcherReducer = (state=initialState, action) => {
	switch (action.type) {
		case types.WATCHER_USER:
			return {
				...state,
				loading: true,
                watcher: {},
                error: ''
			}
		case types.WATCHER_USER_SUCCESS:
			return {
				loading: false,
				watcher: action.payload,
                error:'' 
			}
		case types.WATCHER_USER_ERROR:
			return {
				loading: false,
                watcher: {},
				error: action.payload,
			}
        
        default:
            return state;
            
	}
}

export default watcherReducer;