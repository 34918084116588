import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Nav, Navbar, Form, FormControl, NavDropdown, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { fetchUserLogout } from '../actions/userAction';
import Token from '../constants/Token';

const Styles = styled.div`
  .nav-dropdown{
    background-color:#222;
  }
  a, .navbar-nav, .navbar-light .nav-link {
    color: #E9ECEF;
    &:hover { color: white; }
  }
  .navbar-brand {
    font-size: 1.4em;
    color: #E9ECEF;
    &:hover { color: white; }
  }
  .form-center {
    position: absolute !important;
    left: 25%;
    right: 25%;
  }
  .text-color{
    color: #E9ECEF;
    &:hover { color: white; }
    a{
      color: #E9ECEF;
      text-decoration : None;
      &:hover { color: white; }
    }
  }
`;
export const NavigationBar = () => {

  const [visibility, setVisibility] = useState("visible");
  const user = useSelector(state => state.user.user);
  const dispatch = useDispatch();

  const logout = () => {
    setVisibility("hidden");

    const token = Token();
    dispatch(fetchUserLogout(token));
  }

  return(
      <Styles>
          <Navbar className="ml-auto" fixed="top" expand="lg" variant="dark" bg="dark" style={{'visibility': visibility}}>
            <Container fluid>
                <Navbar.Brand href="/">
                    <i className="fas fa-bars"></i>{' '}
                </Navbar.Brand>
                <Navbar.Brand href="/">
                  App Field
                </Navbar.Brand>
                <Navbar.Toggle />
                <Form className="form-center">
                  <FormControl type="text" placeholder="Search" className="" />
                </Form>
                <Navbar.Collapse id="navbar-dark-example" className="justify-content-end">
                  <Nav>
                      <NavDropdown title={`${user.user.surname} ${user.user.name}`}
                        variant="dark" 
                        id="nav-dropdown-dark-example">
                        <NavDropdown.Item href="">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={logout}>Log out</NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
              </Container>
          </Navbar>
      </Styles>
    );
}