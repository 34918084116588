import React from 'react';
import styled from 'styled-components';

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 6em;
  margin-left: 6em;
  margin-right: 2.3em;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(25px, auto);
  background-color: #83A7FF;
`; 
export const Balisticks = () => {
    return(
        <GridWrapper>
            <h2>Balisticks</h2>
        </GridWrapper>
    );
}

export default Balisticks; 