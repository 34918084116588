import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchUserWatcher} from '../../../actions/watcherAction';
import Token from '../../../constants/Token';

import styled from 'styled-components';
import Card from '../../../components/Card';

import './Watcher.css';

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 6em;
  margin-left: 6em;
  margin-right: 2.5em;
  background-color: #FF8383;
  border-radius: 20px 20px 0% 0%;
  `; 
  const GridWrapper2 = styled.div`
    grid-gap: 10px;
    margin-left: 6em;
    margin-right: 2.5em;
    margin-bottom: 6em;
    height: 1000px;
    display: flex;
    background-color: white;
  `; 
export const Watcher = ({watcherData, fetchUserWatcher}) => {
  const token = Token();
  useEffect(() => {
		fetchUserWatcher(token)
	}, []);
  return(
    <>
      <GridWrapper>
        <h1>Watcher</h1>
           <div  className="watcher">
                    <a href="/watcher"> User List </a>
                    &nbsp;&nbsp;&nbsp;
                    <a href="/created"> Add User </a>
                </div>
      </GridWrapper>
      <GridWrapper2>
      {
        Array.isArray(watcherData.watcher) ? (
          <>
            <div>
              {
                watcherData.watcher.map((item) => (
                  <Card item={item}/>
                ))
              }
            </div>
          </>
        ) : (
          <p>{watcherData.watcher.Error}</p>
        )
      }
    </GridWrapper2>
    </>
  );
}
export default Watcher; 