import React from 'react';
import styled from 'styled-components';
const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 6em;
  margin-left: 6em;
  margin-right: 6em;
  background-color: #83FF87;
`; 
export const MyTree = () => (
  <GridWrapper>
    <h2>My Tree</h2>
    <p>in development</p>
  </GridWrapper>
)

export default MyTree; 