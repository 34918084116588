import React from "react";
import './Card.css'
const Card = ({item}) => {
    return(
        <div className="card">
            <p>{item.name} {item.surname}</p>
            <p>{item.mail}</p>
        </div>
    );
}

export default Card