import React from 'react';
import styled from "styled-components";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class NavItem extends React.Component {
    handleClick = () => {
        const { path, onItemClick } = this.props;
        onItemClick(path);
    }

    render() {
        const { active } = this.props;
        const NavIcon = styled.div`
        a {
            text-decoration: none;
        }`;
        const StyledNavItem = styled.div`
            height: 50px;
            width: 50px; /* width must be same size as NavBar to center */
            text-align: center; /* Aligns <a> inside of NavIcon div */
            margin-bottom: 0;   /* Puts space between NavItems */
            background-color: ${this.props.css};
            border-radius: 10px;
            margin-bottom: 10px;
            margin-left: 5px;
            a {
                font-size: 2em;
                color: ${(props) => props.active ? "white" : "white"};
                text-decoration: none;
                :hover {
                opacity: 0.7;
                text-decoration: none; /* Gets rid of underlining of icons */
                }  
            }
            `;

        return (
            <StyledNavItem active={active}>
                <Link to={this.props.path} onClick={this.handleClick}>
                <NavIcon>{this.props.letter}</NavIcon>
                </Link>
            </StyledNavItem>
        );
    }
}

export default NavItem;