import React from 'react';
import { useHistory, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { NavigationBar } from '../components/NavigationBar';
import App from '../App';
import About from '../pages/About';
import Home from '../pages/Home';
import NoMatch from '../pages/NoMatch';
import Sidebar from '../components/Sidebar';
import Git from '../pages/applications/Git';
import GoldenApple from '../pages/applications/GoldenApple';
import Harmony from '../pages/applications/Harmony';
import Hotline from '../pages/applications/Hotline';
import LeafAPI from '../pages/applications/LeafApi';
import MyTree from '../pages/applications/MyTree';
import RiverFlow from '../pages/applications/RiverFlow';
import SeedStock from '../pages/applications/SeedStock';
import Sundial from '../pages/applications/Sundial';
import Balisticks from '../pages/applications/Balisticks';
import rightTypeContainer from '../containers/rightTypeContainer';
import watcherContainer from '../containers/watcherContainer';

const Connected  = () =>{
    return(
        <>
            <Router>
                <NavigationBar />
                <Sidebar />
                <Switch>
                    <Route exact path="/" component={App} />
                    <Route exact path="/balisticks" component={Balisticks} />
                    <Route exact path="/git" component={Git} />
                    <Route exact path="/golden-apple" component={GoldenApple} />
                    <Route exact path="/harmony" component={Harmony} />
                    <Route exact path="/hotline" component={Hotline} />
                    <Route exact path="/leaf-api" component={LeafAPI} />
                    <Route exact path="/mytree" component={MyTree} />
                    <Route exact path="/river-flow" component={RiverFlow} />
                    <Route exact path="/seed-stock" component={SeedStock} />
                    <Route exact path="/sundial" component={Sundial} />
                    <Route exact path="/watcher" component={watcherContainer} />
                    <Route exact path="/created" component={rightTypeContainer} />
                    <Route path="/about" component={About} />
                    <Route exact path="/home" component={Home} />
                    <Route component={NoMatch} />
                </Switch>
            </Router>
        </>
    );
}

export default Connected;