import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Connected from './Connected';
import {fetchUser, fetchUserLogin} from '../actions/userAction';
import './Login.css'
import Token from '../constants/Token';

const Login =() => {
    const user = useSelector(state => state.user.user);
    const dispatch = useDispatch();

	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");
    const [auth, setAuth] = useState(true);

    const token = Token();

    if(auth){
        try{
            if(token.token_key){
                try{
                    dispatch(fetchUserLogin(token));
                    setAuth(false);
                }catch{
        
                }
            }
        }catch{
            
        }
    }

	const connection = () =>{
		const data = {
			email: login,
			password: password
		};
        dispatch(fetchUser(data));
	}

    return user.Error ? (
        <div id="container">
            <div className="backconnect">
                <h2>AppField</h2>
                <input type="text"
                    onChange={(event) => setLogin(event.target.value)} 
                    placeholder="your.mail@AppField.com"/>

                <input type="password"
                    onChange={(event) => setPassword(event.target.value)} 
                    placeholder="Password"/>

                <button onClick={() => connection()} className="backbutton">
                    Connection
                </button>
                <a href="/home">Retour</a>
                <p style={{color: "red"}}>{user.Error}</p>
            </div>
        </div>
    ) : user.user ? (
        <>
            <Connected/>
        </>
    ) : (
        <div id="container">
            <div className="backconnect">
                <h2>AppField</h2>
                <input type="text"
                    onChange={(event) => setLogin(event.target.value)} 
                    placeholder="your.mail@AppField.com"/>

                <input type="password"
                    onChange={(event) => setPassword(event.target.value)} 
                    placeholder="Password"/>

                <button onClick={() => connection()} className="backbutton">
                    Connection
                </button>
                <a href="/home">Retour</a>
            </div>
        </div>
    );
    
}

export default Login;