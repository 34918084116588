function Token(){
    const token = JSON.parse(localStorage.getItem('token'));
    try{
        const token_u = {
            token_user:token.token_user,
            token_key:token.token_key
        };
        return token_u;
    }catch{
        return null;
    }
}

export default Token;