import React from 'react';
import styled from 'styled-components';
const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 6em;
  margin-left: 6em;
  margin-right: 6em;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(25px, auto);
  background-color: #F683FF;
`; 
export const Hotline = () => (
  <GridWrapper>
    <h2>Hotline</h2>
    <p>in development</p>
  </GridWrapper>
)

export default Hotline; 