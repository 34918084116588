import * as types from '../constants/ActionTypes'

const initialState = {
    loading: false,
    righttype: {},
    error: ''
}

const rightTypeReducer = (state=initialState, action) => {
	switch (action.type) {
		case types.RIGHTTYPE:
			return {
				...state,
				loading: true,
                righttype: {},
                error: ''
			}
		case types.RIGHTTYPE_SUCCESS:
			return {
				loading: false,
				righttype: action.payload,
                error:'' 
			}
		case types.RIGHTTYPE_ERROR:
			return {
				loading: false,
                righttype: {},
				error: action.payload,
			}
        
        default:
            return state;
            
	}
}

export default rightTypeReducer;