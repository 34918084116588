import axios from 'axios';
import * as APIConfig from '../constants/APIConfig'
import * as types from '../constants/ActionTypes'
import { useHistory } from "react-router-dom";

export const fetchLogin = () => ({
	type: types.LOGIN_USER,
})

export const fetchLoginSuccess = user => ({
	type: types.LOGIN_USER_SUCCESS,
	payload: user
})

export const fetchLoginError = error => ({
	type: types.LOGIN_USER_ERROR,
	payload: error
})

/*export const fetchUser = (data) => {
	return async (dispatch) => {
		dispatch(fetchLogin())
        fetch(
            `http://localhost:5002/api/v1/login`,
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Access-Control-Expose-Headers":"Access-Control-*",
                    "Access-Control-Allow-Headers": "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept",
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
                    'Allow': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                method: 'POST',
                mode: 'cors',
                body:JSON.stringify(data)
            })
        .then(response =>{
            const user = response.data;
            console.log(user);
            dispatch(fetchLoginSuccess(user));
            if(user.user){
                localStorage.setItem('user', JSON.stringify(user.user));
                localStorage.setItem('token',JSON.stringify(user.token));
            }
        })
        .catch (error => {
            const errormsg = error.message;
            dispatch(fetchLoginError(errormsg));
        })
	}
}*/

export const fetchUser = (data) => {
	return async (dispatch) => {
		dispatch(fetchLogin())
        axios.post(
            `${APIConfig.API_URI}/login`,
            data,
            {
            headers: APIConfig.HEADERS,
            method: 'post',
           })
        .then(response =>{
            const user = response.data;
            dispatch(fetchLoginSuccess(user));
            if(user.user){
                localStorage.setItem('user', JSON.stringify(user.user));
                localStorage.setItem('token',JSON.stringify(user.token));
            }
        })
        .catch (error => {
            const errormsg = error.message;
            dispatch(fetchLoginError(errormsg));
        })
	}
}


export const fetchUserLogin = (data) => {
	return async (dispatch) => {
		dispatch(fetchLogin())
        axios.post(
            `${APIConfig.API_URI}/login_auto`,
            data,
            {
            headers: APIConfig.HEADERS,
            method: 'post',
           })
        .then(response =>{
            const user = response.data;
            dispatch(fetchLoginSuccess(user));
            if(user.user){
                localStorage.setItem('user', JSON.stringify(user.user));
                localStorage.setItem('token',JSON.stringify(user.token));
            }
        })
        .catch (error => {
            const errormsg = error.message;
            dispatch(fetchLoginError(errormsg));
        })
	}
}

export const fetchUserLogout = (data) => {
	return async (dispatch) => {
		dispatch(fetchLogin())
        axios.post(
            `${APIConfig.API_URI}/logout`,
            data,
            {
            headers: APIConfig.HEADERS,
            method: 'post',
           })
        .then(response =>{
            const user = response.data;
            dispatch(fetchLoginSuccess(user));
            
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            const history = useHistory();
            history.push("/home");
            
        })
        .catch (error => {
            const errormsg = error.message;
            dispatch(fetchLoginError(errormsg));
        })
	}
}