import React from 'react';
import styled from 'styled-components';
const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 6em;
  margin-left: 6em;
  margin-right: 6em;
  background-color: #FFC183;
  border-radius: 20px 20px 0% 0%;
`; 
const GridWrapper2 = styled.div`
  grid-gap: 10px;
  margin-left: 6em;
  margin-right: 6em;
  background-color: white;
`; 
export const Sundial = () => (
    <>
        <GridWrapper>
            <h1>Sundial</h1>
        </GridWrapper>
        <GridWrapper2>
            <p>EJEJEJJEJEJEJJEJEJE
                EJEJEEOE
                EJEIOEOE
            </p>
        </GridWrapper2>
    </>
)

export default Sundial; 